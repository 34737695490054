
import { defineAsyncComponent, defineComponent } from "vue";

export default defineComponent({
  name: "HomeView",
  components: {
    Header: defineAsyncComponent(
      () => import("../components/landing/Header.vue")
    ),
    SectionHelp: defineAsyncComponent(
      () => import("@/components/landing/SectionHelp.vue")
    ),
    SectionPremium: defineAsyncComponent(
      () => import("@/components/landing/SectionPremium.vue")
    ),
    SectionTestimonial: defineAsyncComponent(
      () => import("@/components/landing/SectionTestimonial.vue")
    ),
    SectionOne: defineAsyncComponent(
      () => import("@/components/landing/SectionOne.vue")
    ),
    SectionRecommended: defineAsyncComponent(
      () => import("@/components/landing/SectionRecommended.vue")
    ),
    Footer: defineAsyncComponent(
      () => import("@/components/landing/Footer.vue")
    ),
    SectionWhy: defineAsyncComponent(
      () => import("@/components/landing/SectionWhy.vue")
    ),
    SectionImmerse: defineAsyncComponent(
      () => import("@/components/landing/SectionImmerse.vue")
    ),
    SectionFeatured: defineAsyncComponent(
      () => import("@/components/landing/SectionFeatured.vue")
    ),
    SectionPurpose: defineAsyncComponent(
      () => import("@/components/landing/SectionPurpose.vue")
    ),
    SectionFaq: defineAsyncComponent(
      () => import("@/components/landing/SectionFaq.vue")
    ),
    SectionBlog: defineAsyncComponent(
      () => import("@/components/landing/SectionBlog.vue")
    ),
  },
});
