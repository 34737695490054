import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_section_one = _resolveComponent("section-one")!
  const _component_section_recommended = _resolveComponent("section-recommended")!
  const _component_section_why = _resolveComponent("section-why")!
  const _component_section_immerse = _resolveComponent("section-immerse")!
  const _component_section_featured = _resolveComponent("section-featured")!
  const _component_section_testimonial = _resolveComponent("section-testimonial")!
  const _component_section_premium = _resolveComponent("section-premium")!
  const _component_section_purpose = _resolveComponent("section-purpose")!
  const _component_section_faq = _resolveComponent("section-faq")!
  const _component_section_blog = _resolveComponent("section-blog")!
  const _component_section_help = _resolveComponent("section-help")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createVNode(_component_section_one),
    _createVNode(_component_section_recommended),
    _createVNode(_component_section_why),
    _createVNode(_component_section_immerse),
    _createVNode(_component_section_featured),
    _createVNode(_component_section_testimonial),
    _createVNode(_component_section_premium),
    _createVNode(_component_section_purpose),
    _createVNode(_component_section_faq),
    _createVNode(_component_section_blog),
    _createVNode(_component_section_help),
    _createVNode(_component_Footer)
  ]))
}